import { Box, Paper, Stack, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  activeDaySelector,
  filteredOpportunitiesSelector,
} from "../../features/app/selectors";
import { activateDay } from "../../features/app/slice";
import Form from "./Form";

export default function Records() {
  const dispatch = useDispatch();
  const activeDay = useSelector(activeDaySelector);
  const opportunities = useSelector(filteredOpportunitiesSelector);

  return (
    <Paper elevation={0} sx={{ p: 2, pb: 8 }}>
      <Stack spacing={4}>
        <Typography variant="h3" component="h2">
          Records
        </Typography>
        <Box>
          <DatePicker
            value={dayjs(activeDay)}
            onAccept={(v) => {
              if (v) {
                dispatch(activateDay(v.toDate().setUTCHours(0, 0, 0, 0)));
              }
            }}
          />
        </Box>
        <Stack spacing={4}>
          {opportunities.map((opportunity) => (
            <Form readonly key={opportunity.id} value={opportunity} />
          ))}
        </Stack>
      </Stack>
    </Paper>
  );
}
