import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import Papa from "papaparse";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  activeDaySelector,
  filteredOpportunitiesSelector,
} from "../../features/app/selectors";
import { activateDay } from "../../features/app/slice";

function boolToYN(value?: boolean) {
  return value ? "Y" : "N";
}

export default function Analytics() {
  const dispatch = useDispatch();
  const activeDay = useSelector(activeDaySelector);
  const opportunities = useSelector(filteredOpportunitiesSelector);
  const [loading, setLoading] = useState(false);

  const csvData = useMemo(() => {
    return opportunities.map((o) => ({
      date: dayjs(o.index).format("YYYY-MM-DDTHH:mm:ssZ[Z]"),
      hcw: o.code,
      "bef-pat.": boolToYN(o.indication.beforePatient),
      "bef-asept.": boolToYN(o.indication.beforeAsepticTechnique),
      "aft-b.f.": boolToYN(o.indication.afterBodyFluids),
      "aft-pat.": boolToYN(o.indication.afterPatient),
      "aft-p.surr.": boolToYN(o.indication.afterPatientSurroundings),
      hr: boolToYN(o.actions.handRub.gloved || o.actions.handRub.missed),
      hw: boolToYN(o.actions.handWash.gloved || o.actions.handWash.missed),
      missed: boolToYN(o.actions.handRub.missed || o.actions.handWash.missed),
      gloved: boolToYN(o.actions.handRub.gloved || o.actions.handWash.gloved),
    }));
  }, [opportunities]);

  function handleExport() {
    setLoading(true);
    const csv = Papa.unparse(csvData);
    const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const csvUrl = URL.createObjectURL(csvBlob);

    const csvLink = document.createElement("a");
    csvLink.href = csvUrl;
    csvLink.download = `monitoring-${dayjs(activeDay).format(
      "YYYY-MM-DD"
    )}.csv`;
    csvLink.click();
    setLoading(false);
  }

  return (
    <Paper elevation={0} sx={{ p: 2, pb: 8 }}>
      <Stack spacing={4}>
        <Typography variant="h3" component="h2">
          Analytics
        </Typography>
        <Box>
          <DatePicker
            value={dayjs(activeDay)}
            onAccept={(v) => {
              if (v) {
                dispatch(activateDay(v.toDate().setUTCHours(0, 0, 0, 0)));
              }
            }}
          />
        </Box>
        <Box>
          <LoadingButton
            size="large"
            variant="contained"
            loading={loading}
            onClick={handleExport}
          >
            Export
          </LoadingButton>
        </Box>
      </Stack>
    </Paper>
  );
}
