import { Firestore, collection, doc, setDoc } from "firebase/firestore";
import { Opportunity } from "../models/compliance";

export default class Persistence {
  public static async createOpportunity(
    firestore: Firestore,
    data: Pick<Opportunity, "code" | "actions" | "indication">
  ) {
    const now = new Date();
    const ref = doc(collection(firestore, "opportunities"));
    const opportunity: Opportunity<Date> = {
      ...data,
      id: ref.id,
      index: now.valueOf(),
      createdAt: new Date(now.setUTCHours(0, 0, 0, 0)),
    };

    await setDoc(ref, opportunity, { merge: true });
  }
}
