export interface Indication {
  beforePatient: boolean;
  beforeAsepticTechnique: boolean;
  afterBodyFluids: boolean;
  afterPatient: boolean;
  afterPatientSurroundings: boolean;
}

export enum ActionType {
  HandRub = "handRub",
  HandWash = "handWash",
}

export interface Execution {
  missed: boolean;
  gloved: boolean;
}

export type Actions = Record<ActionType, Execution>;

export enum HCWCode {
  Doctors = "Doctors",
  Nurses = "Nurses",
  RespiratoryTherapists = "Respiratory Therapists",
  NursingAttendants = "Nursing Attendants",
  MedicalStudents = "Medical Students",
  RadiologyTechnicians = "Radiology Technicians",
  LaboratoryStaff = "Laboratory Staff",
  HouseKeepingStaff = "House Keeping Staff",
  NursingStudents = "Nursing Students",
  Watchers = "Watchers",
}

export interface Opportunity<T = unknown> {
  id: string;
  code: string;
  actions: Actions;
  indication: Indication;
  createdAt?: T;
  updatedAt?: T;
  index: number;
}
