import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  Radio,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { logEvent } from "firebase/analytics";
import { useEffect, useState } from "react";
import { useAnalytics, useFirestore } from "reactfire";
import { HCWCode, Indication, Opportunity } from "../../models/compliance";
import {
  defaultActions,
  defaultExecution,
  defaultIndication,
  defaultOpportunity,
} from "../../models/defaults";
import Persistence from "../../services/Persistence";

interface Props {
  value?: Opportunity<number>;
  readonly?: boolean;
}

export default function Form({
  value = { ...defaultOpportunity },
  readonly = false,
}: Props) {
  const firestore = useFirestore();
  const analytics = useAnalytics();
  const [loading, setLoading] = useState(false);

  // Indications
  const [code, setCode] = useState<string>(value.code);
  const [indication, setIndication] = useState(value.indication);
  const [actions, setActions] = useState(value.actions);

  // Actions
  const [handRubChecked, setHandRubChecked] = useState(
    actions.handRub.missed || actions.handRub.gloved
  );
  const [handWashChecked, setHandWashChecked] = useState(
    actions.handWash.missed || actions.handWash.gloved
  );

  const [missed, setMissed] = useState(
    actions.handRub.missed || actions.handWash.missed
  );
  const [gloved, setGloved] = useState(
    actions.handRub.gloved || actions.handWash.gloved
  );

  const handleSave = async () => {
    setLoading(true);
    await Persistence.createOpportunity(firestore, {
      code,
      indication,
      actions,
    });

    logEvent(analytics, "create_record", { code });

    setCode("");
    setActions({ ...defaultActions });
    setIndication({ ...defaultIndication });
    setHandRubChecked(false);
    setHandWashChecked(false);
    setMissed(false);
    setGloved(false);
    setLoading(false);
  };

  const handleIndicationChange = (field: keyof Indication, value: boolean) => {
    const nextIndication = { ...indication };
    nextIndication[field] = value;
    setIndication(nextIndication);
  };

  useEffect(() => {
    const handRub = { ...defaultExecution };
    const handWash = { ...defaultExecution };

    if (handRubChecked) {
      handRub.missed = missed;
      handRub.gloved = gloved;
    }

    if (handWashChecked) {
      handWash.missed = missed;
      handWash.gloved = gloved;
    }

    setActions({ handRub, handWash });
  }, [handRubChecked, handWashChecked, missed, gloved]);

  return (
    <Box component="form" autoComplete="off">
      <Grid container spacing={2} columns={12}>
        <Grid item xs={12}>
          <TextField
            select
            required
            fullWidth
            label="HCW Code"
            value={code}
            disabled={readonly}
            onChange={(e) => setCode(e.target.value)}
          >
            {Object.values(HCWCode).map((hcwCode, index) => (
              <MenuItem key={hcwCode} value={hcwCode}>
                {`${index + 1} - ${hcwCode}`}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">Indication</FormLabel>
            <FormGroup>
              <FormControlLabel
                checked={indication.beforePatient}
                control={<Checkbox />}
                label="bef-pat."
                disabled={readonly}
                onChange={(_, v) => handleIndicationChange("beforePatient", v)}
              />
              <FormControlLabel
                checked={indication.beforeAsepticTechnique}
                control={<Checkbox />}
                label="bef-asept."
                disabled={readonly}
                onChange={(_, v) =>
                  handleIndicationChange("beforeAsepticTechnique", v)
                }
              />
              <FormControlLabel
                checked={indication.afterBodyFluids}
                control={<Checkbox />}
                label="aft-b.f."
                disabled={readonly}
                onChange={(_, v) =>
                  handleIndicationChange("afterBodyFluids", v)
                }
              />
              <FormControlLabel
                checked={indication.afterPatient}
                control={<Checkbox />}
                label="aft-pat."
                disabled={readonly}
                onChange={(_, v) => handleIndicationChange("afterPatient", v)}
              />
              <FormControlLabel
                checked={indication.afterPatientSurroundings}
                control={<Checkbox />}
                label="aft-p.surr."
                disabled={readonly}
                onChange={(_, v) =>
                  handleIndicationChange("afterPatientSurroundings", v)
                }
              />
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">HH Action</FormLabel>
            <FormGroup>
              <FormControlLabel
                checked={handRubChecked}
                control={<Checkbox />}
                label="HR"
                disabled={readonly}
                onChange={(_, v) => setHandRubChecked(v)}
              />
              <FormControlLabel
                checked={handWashChecked}
                control={<Checkbox />}
                label="HW"
                disabled={readonly}
                onChange={(_, v) => setHandWashChecked(v)}
              />
              {/* <RadioGroup defaultValue="missed"> */}
              <FormControlLabel
                checked={missed}
                control={<Radio />}
                label="Missed"
                disabled={readonly}
                onClick={() => setMissed(!missed)}
              />
              <FormControlLabel
                checked={gloved}
                control={<Radio />}
                label="Gloved"
                disabled={readonly}
                onClick={() => setGloved(!gloved)}
              />
              {/* </RadioGroup> */}
            </FormGroup>
          </FormControl>
        </Grid>

        {!readonly && (
          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              size="large"
              variant="contained"
              loading={loading}
              disabled={!code}
              onClick={handleSave}
            >
              Save
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
