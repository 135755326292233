import { configureStore } from "@reduxjs/toolkit";
import appReducer, { AppSlice } from "./app/slice";

export const store = configureStore({
  reducer: {
    app: appReducer,
  },
});

export type RootState = {
  app: AppSlice;
};
