import { Update } from "@reduxjs/toolkit";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFirestore } from "reactfire";
import opportunityConverter from "../converters/opportunityConverter";
import { activeDaySelector } from "../features/app/selectors";
import {
  addOpportunities,
  removeOpportunities,
  updateOpportunities,
} from "../features/app/slice";
import { Opportunity } from "../models/compliance";

export default function OpportunitySubscription() {
  const dispatch = useDispatch();
  const firestore = useFirestore();
  const activeDay = useSelector(activeDaySelector);

  useEffect(() => {
    const date = new Date(activeDay);
    const opportunitiesQuery = query(
      collection(firestore, "opportunities").withConverter(
        opportunityConverter
      ),
      where("createdAt", "==", date)
    );

    onSnapshot(opportunitiesQuery, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const added: Array<Opportunity<number>> = [];
        const modified: Array<Update<Opportunity<number>>> = [];
        const removed: Array<string> = [];
        const data = change.doc.data();

        switch (change.type) {
          case "added":
            added.push(data);
            break;
          case "modified":
            modified.push({
              id: change.doc.id,
              changes: data,
            });
            break;
          case "removed":
            removed.push(change.doc.id);
            break;
          default:
        }

        if (added.length) {
          dispatch(addOpportunities(added));
        }

        if (modified.length) {
          dispatch(updateOpportunities(modified));
        }

        if (removed.length) {
          dispatch(removeOpportunities(removed));
        }
      });
    });
  }, [activeDay, dispatch, firestore]);

  return null;
}
