import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { OpportunityAdapter } from "./slice";

export const activeDaySelector = createSelector(
  (state: RootState) => state.app.activeDay,
  (activeDay) => activeDay
);

export const opportunitySelectors = OpportunityAdapter.getSelectors(
  (state: RootState) => state.app.opportunities
);

export const filteredOpportunitiesSelector = createSelector(
  activeDaySelector,
  opportunitySelectors.selectAll,
  (activeDay, opportunities) => {
    return opportunities.filter((opportunity) => {
      return opportunity.createdAt === activeDay;
    });
  }
);
