import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
  Update,
} from "@reduxjs/toolkit";
import { Opportunity } from "../../models/compliance";

export type AppSlice = {
  activeDay: number;
  opportunities: EntityState<Opportunity<number>>;
};

export const OpportunityAdapter = createEntityAdapter<Opportunity<number>>({
  selectId: (opportunity) => opportunity.id,
  sortComparer: (a, b) => b.index - a.index,
});

const initialState: AppSlice = {
  activeDay: new Date().setUTCHours(0, 0, 0, 0),
  opportunities: OpportunityAdapter.getInitialState(),
};

const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    activateDay: (state, action: PayloadAction<number>) => {
      state.activeDay = action.payload;
    },
    addOpportunities: (state, action: PayloadAction<Opportunity<number>[]>) => {
      OpportunityAdapter.addMany(state.opportunities, action.payload);
    },
    removeOpportunities: (state, action: PayloadAction<string[]>) => {
      OpportunityAdapter.removeMany(state.opportunities, action.payload);
    },
    updateOpportunities: (
      state,
      action: PayloadAction<Update<Opportunity<number>>[]>
    ) => {
      OpportunityAdapter.updateMany(state.opportunities, action.payload);
    },
  },
});

export const {
  activateDay,
  addOpportunities,
  removeOpportunities,
  updateOpportunities,
} = slice.actions;
export default slice.reducer;
