import { FirestoreDataConverter, Timestamp } from "firebase/firestore";
import { Opportunity } from "../models/compliance";

const opportunityConverter: FirestoreDataConverter<Opportunity<number>> = {
  toFirestore() {
    throw new Error("Not implemented");
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options) as Opportunity<Timestamp>;
    return {
      id: snapshot.id,
      code: data.code,
      actions: data.actions,
      indication: data.indication,
      createdAt: data.createdAt?.toMillis(),
      updatedAt: data.updatedAt?.toMillis(),
      index: data.index,
    };
  },
};

export default opportunityConverter;
