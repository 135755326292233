import { Stack } from "@mui/material";
import { logEvent } from "firebase/analytics";
import { Suspense, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import { useAnalytics, useUser } from "reactfire";
import OpportunitySubscription from "../subscriptions/Opportunity";
import Add from "./Compliance/Add";
import Analytics from "./Compliance/Analytics";
import Login from "./Compliance/Login";
import Records from "./Compliance/Records";
import Navigation from "./Navigation";

export default function Layout() {
  const user = useUser();
  const location = useLocation();
  const analytics = useAnalytics();
  const navigation = useNavigate();

  useEffect(() => {
    logEvent(analytics, "view_page", { page_path: location.pathname });
  }, [location, analytics]);

  useEffect(() => {
    if (!user.data) {
      navigation("/");
    }
  }, [navigation, user]);

  return (
    <Stack>
      <Suspense fallback="loading">
        <Navigation />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/add" element={<Add />} />
          <Route path="/records" element={<Records />} />
          <Route path="/analytics" element={<Analytics />} />
        </Routes>
        <OpportunitySubscription />
      </Suspense>
    </Stack>
  );
}
