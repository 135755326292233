import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { FirebaseAppProvider } from "reactfire";
import App from "./App";
import { store } from "./features/store";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";

const firebaseConfig = {
  apiKey: "AIzaSyBJjIhT8CyRBnqfd8koPSDu9WMqLii0-_U",
  authDomain: "hand-hygiene-monitoring-tool.firebaseapp.com",
  projectId: "hand-hygiene-monitoring-tool",
  storageBucket: "hand-hygiene-monitoring-tool.appspot.com",
  messagingSenderId: "360568241329",
  appId: "1:360568241329:web:3894aff022a3620c78b690",
  measurementId: "G-WJBYNC6JF0",
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <Provider store={store}>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <App />
            </ThemeProvider>
          </BrowserRouter>
        </Provider>
      </FirebaseAppProvider>
    </LocalizationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
