import {
  Box,
  Button,
  FormControlLabel,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { signInWithEmailAndPassword } from "firebase/auth";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAuth, useUser } from "reactfire";

export default function Login() {
  const auth = useAuth();
  const user = useUser();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigation = useNavigate();

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    if (email && password) {
      try {
        await signInWithEmailAndPassword(auth, email, password);
        navigation("/add");
      } catch (e: unknown) {
        if (e instanceof Error) {
          console.error(e.message);
        }
      } finally {
        setEmail("");
        setPassword("");
      }
    }
  }

  useEffect(() => {
    if (user.data) {
      navigation("/add");
    }
  }, [navigation, user])

  return (
    <Paper elevation={0} sx={{ p: 2, pb: 8 }}>
      <Stack spacing={4}>
        <Typography variant="h3" component="h2">
          Login
        </Typography>

        <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
          <FormControlLabel
            required
            label="Email"
            value={email}
            control={
              <TextField onChange={(e) => setEmail(e.target.value)} fullWidth />
            }
          />
          <FormControlLabel
            required
            label="Password"
            value={password}
            control={
              <TextField
                type="password"
                fullWidth
                onChange={(e) => setPassword(e.target.value)}
              />
            }
          />
          <Button fullWidth size="large" type="submit" variant="contained">
            Save
          </Button>
        </Box>
      </Stack>
    </Paper>
  );
}
