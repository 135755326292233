import { Actions, Execution, Indication, Opportunity } from "./compliance";

export const defaultExecution: Execution = {
  missed: false,
  gloved: false,
} as const;

export const defaultActions: Actions = {
  handRub: { ...defaultExecution },
  handWash: { ...defaultExecution },
} as const;

export const defaultIndication: Indication = {
  beforePatient: false,
  beforeAsepticTechnique: false,
  afterBodyFluids: false,
  afterPatient: false,
  afterPatientSurroundings: false,
} as const;

export const defaultOpportunity: Opportunity<number> = {
  id: "",
  code: "",
  actions: { ...defaultActions },
  indication: { ...defaultIndication },
  index: 0,
} as const;
