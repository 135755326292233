import { Paper, Stack, Typography } from "@mui/material";
import Form from "./Form";

export default function Add() {
  return (
    <Paper elevation={0} sx={{ p: 2 }}>
      <Stack spacing={4}>
        <Typography variant="h3" component="h2">
          Add
        </Typography>
        <Form />
      </Stack>
    </Paper>
  );
}
