import AddCircleIcon from "@mui/icons-material/AddCircle";
import BarChartIcon from "@mui/icons-material/BarChart";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { Paper } from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { useState } from "react";
import { useNavigate } from "react-router";

export default function Navigation() {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
      }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          label="Add"
          value="add"
          icon={<AddCircleIcon />}
          onClick={() => navigate("/add")}
        />
        <BottomNavigationAction
          label="Records"
          value="records"
          icon={<ListAltIcon />}
          onClick={() => navigate("/records")}
        />
        <BottomNavigationAction
          label="Analytics"
          value="analytics"
          icon={<BarChartIcon />}
          onClick={() => navigate("/analytics")}
        />
      </BottomNavigation>
    </Paper>
  );
}
