import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {
  AnalyticsProvider,
  AuthProvider,
  FirestoreProvider,
  useFirebaseApp,
} from "reactfire";
import Layout from "./components/Layout";

function App() {
  const app = useFirebaseApp();
  const authInstance = getAuth(app);
  const firestoreInstance = getFirestore(app);
  const analyticsInstance = getAnalytics(app);

  return (
    <AuthProvider sdk={authInstance}>
      <FirestoreProvider sdk={firestoreInstance}>
        <AnalyticsProvider sdk={analyticsInstance}>
          <Layout />
        </AnalyticsProvider>
      </FirestoreProvider>
    </AuthProvider>
  );
}

export default App;
